








































import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  computed: {
    ...mapState(["dates"]),
  },
  methods: {
    formatText(text: string) {
      text = text.replaceAll("[PROMOSTART]", this.dates.promoStartDate);
      text = text.replaceAll("[PROMOEND]", this.dates.promoEndDate);
      text = text.replaceAll("[OLDEST]", this.dates.dateOldest);
      text = text.replaceAll("[YOUNGEST]", this.dates.dateYoungest);

      return text;
    },
  },
  data: () => ({
    articles1: [
      {
        title: "1.1 Informations sur l'éditeur",
        text: `
        <p>
          Communauté de Communes du Quercy Caussadais<br/>
          264, route de Treilhou<br/>
          82300 CAUSSADE<br/>
          Tél : 05 63 93 28 66
        </p>
        <p>
          <strong>Directeur de la publication</strong><br/>
          Guy ROUZIES, Président de la Communauté de Communes du Quercy Caussadais, 
          Pour toute suggestion, information, ou réaction concernant ce site, n’hésitez pas à nous écrire par courriel à :  <a href="mailto:communautedecommunes@quercycaussadais.fr">communautedecommunes@quercycaussadais.fr</a>
        </p>
        <p>
        <strong>Hébergement</strong><br/>
          OVH, 2 Rue Kellermann, 59100 ROUBAIX, France<br/>
          téléphone: +33 8 99 70 17 61<br/>
          e-mail: support@ovh.net<br/>
          website: http://www.ovh.com
        </p>
          `,
      },
      {
        title: "1.2 Informations générales",
        text: `<p>
        <strong>Sources documentaires</strong><br/>
          Le contenu des pages du site a été rédigé à partir des sources documentaires de la Communauté de Communes du Quercy Caussadais.
        </p>
        <p>
          <strong>Traitement des données personnelles</strong><br/>
          Les données récoltées (qui ont fait l’objet d’une inscription volontaire) par le biais du formulaire de contact ne seront en aucun cas communiquées à des tiers. Elles seront utilisées seulement pour communiquer avec l'utilisateur inscrit.<br/>
          En vertu de la loi n°78-17 du 6 janvier 1978, relative à l'informatique, aux fichiers et aux libertés, et notamment ses articles 27 et 40 :<br/>
          Les personnes nominées ont un droit d'accès et de rectification pour les données les concernant ; de même que chacun a le droit de s'opposer à ce que des informations nominatives le concernant fassent l'objet d'un traitement. <br/>
          Ces droits peuvent être exercés sur simple demande écrite par le biais de notre formulaire de contact.
        </p>`,
      },
      {
        title: "1.3 Informations techniques",
        text: `
          <p>
            Ce site repose sur des logiciels libres, sous licence MIT :<br/>
            <ul>
              <li>Serveur : Node.js</li>
              <li>Client : Vue.js, Vuetify.js</li>
            </ul>
          </p>
          <p>
            <strong>Accessibilité</strong><br/>
            Ce site se conforme aux standards HTML5 et CSS3 du W3C.<br/>
            L'affichage des pages du site est compatible avec les résolutions d'écran allant de 320px de large minimum à 3840px de large maximum<br/>
            La consultation des pages est possible avec tout navigateur moderne (IE11 et +, Edge, Chrome, Firefox, Safari et versions mobile)
          </p>
          <p>
            <strong>Logiciels tiers</strong><br />
            Certains documents sont proposés au format PDF. Pour pouvoir les ouvrir, les consulter et les imprimer, vous pouvez utiliser le module Acrobat Reader de la société Adobe.<br/>
            Vous pouvez le télécharger en cliquant <a href="https://get.adobe.com/fr/reader/" target="_blank">ici</a>. 
          </p>
              `,
      },
      {
        title: "1.4 L'utilisateur",
        text: `
          <p>
            <strong>Copyright - Droits réservés</strong><br/>
            Dans le cadre d’un usage autre que strictement privé, ne pas résumer, modifier, altérer le contenu textuel ni les données cartographiques du site Internet sans autorisation préalable de l’éditeur</p>
          <p>
            Pour un usage privé, la reproduction partielle sur support papier des données textuelles contenues sur le site « www.couponasso-quercycaussadais.fr » est libre. Elle peut être réalisée sans autorisation de l’éditeur du site. Cette reproduction ne peut cependant être présentée comme une version officielle.
          </p>
            L’autorisation de reproduire est accordée à l’utilisateur sans paiement de droits, sous réserve que le demandeur s'engage à citer le nom des propriétaires du site Internet et l´adresse du site Internet (avec un lien hypertexte) et sans modification.
          </p>  
          <p>
            <strong>Cookies</strong><br/>
            Pour des raisons liées à la technologie employée par le prestataire chargé de la mesure de l'audience du site, un "cookie" peut vous être proposé au cours de votre navigation.<br/>
            L'accès au site n'est en aucun cas conditionné à l'acceptation de ce cookie.
          </p>
          <p>
            <strong>Création de liens hypertextes</strong><br/>
            Il est possible de créer un lien vers le site « www.couponasso-quercycaussadais.fr » sans autorisation expresse de l’éditeur, à la seule condition que ce lien ouvre une nouvelle fenêtre du navigateur Internet.<br/> 
            Toutefois, l’éditeur se réserve le droit de demander la suppression d’un lien qu’il estime non conforme à sa politique éditoriale.
          </p>
        `,
      },
      {
        title: "1.5 L'éditeur",
        text: `
          <p>
            L’éditeur du site est représenté par Monsieur le Président de la Communauté de Communes du Quercy Caussadais.
          </p>
          <p>
            <strong>Clause de non-responsabilité</strong><br/>
            Les informations qui figurent sur ce site font l’objet d’une clause de non responsabilité.<br/>
            L’éditeur s’efforcera de diffuser des informations exactes et à jour, ainsi que de corriger les erreurs qui lui seront signalées.<br/>
            La Communauté de Communes du Quercy Caussadais ne peut toutefois garantir l'exactitude de toutes les informations présentes et par conséquent, décline toute responsabilité quant à son contenu.<br/>
          </p>
        `,
      },
    ],
    articles2: [
      {
        title: "2.1 Finalité de la collecte des données personnelles",
        text: `
          <p>
            Les données collectées sont communiquées, si besoin, au seul personnel de la Communauté de Communes du Quercy Caussadais dans le but de contrôler votre éligibilité aux coupons associations.<br/>
            Le personnel de la Communauté de Communes qui, du fait de sa fonction, aurait accès aux données des utilisateurs, est tenu à la plus grande confidentialité à cet égard.<br/>
          </p>
        `,
      },
      {
        title: "2.2 Conservation et partage des données",
        text: `
          <p>
            "Les données personnelles sont conservées au maximum 2 mois après la fin de l'opération. Elles ne seront en aucun cas partagées."<br/>
            Toutefois, la communauté de communes ne maitrise pas les risques liés au fonctionnement d’Internet et attire l’attention des utilisateurs sur l’existence d’éventuels risques en termes de pertes ponctuelles de données ou d’atteinte à la confidentialité des données transitant via ce réseau. <br/>
            Les informations proposées sur le site pourront être interrompues pour des cas de force majeure ou indépendants de la volonté de la Communauté de Communes du Quercy Caussadais ou de faits ne relevant pas de sa responsabilité.
          </p>
        `,
      },
      {
        title: "2.3 Droits des utilisateurs sur les données collectées",
        text: `
          <p>
            Vous pouvez accéder aux données vous concernant, les rectifier, demander leur effacement ou exercer votre droit à la limitation du traitement de vos données.<br/>
            Vous pouvez retirer à tout moment votre consentement au traitement de vos données<br/>
            Vous pouvez également vous opposer au traitement de vos données<br/>
          </p>
          <p>
            Consultez le site cnil.fr pour plus d’informations sur vos droits.
            Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter (le cas échéant, notre délégué à la protection des données ou le service chargé de l’exercice de ces droits).
          </p>
        `,
      },
      {
        title: "2.4 La Comission Nationale de l'Informatique et des Libertés",
        text: `
          <p>
            Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation auprès de la CNIL sur leur site internet.
          </p>
        `,
      },
    ],
  }),
});
